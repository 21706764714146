html {
  overflow-y: scroll;
}

body {
  font-family: "Roboto", sans-serif;
  background-size: cover;
  min-height: 101%;
}
.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  /* color: #ffffff !important; */
  /* opacity: 1; Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  /* color: #ffffff !important; */
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  /* color: #ffffff !important; */
}
.form-control {
  outline: none;
  border: 1px solid #28696d;
}
.form-control:focus {
  border-color: #28696d;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px #28696d;
}
.text-boston {
  color: #28696d;
}
